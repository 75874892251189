.edit-profile {
  .edit-profile-content {
    box-shadow: 2px 10px 10px 0px #0000000a;
    border-radius: 12px;
    .edit-profile-img-con {
      width: 130px;
      height: 130px;
    }
    .edit-profile-btn {
      width: 150px;
    }
  }
  .input-field-con {
    min-height: 111.5px;
  }
  .input-field-con-password {
    min-height: 131.5px;
  }
  .current-pass-con {
    min-height: 131.5px;
  }

  .change-pass-input-con {
    max-width: 400px;
  }

  @media (max-width: 1199.9px) {
    .edit-profile-img-con {
      margin-right: auto;
      margin-left: auto;
    }
    .current-pass-con {
      min-height: 111.5px;
    }
  }
  @media (max-width: 767.9px) {
    .input-field-con,
    .input-field-con-password,
    .current-pass-con {
      min-height: inherit;
    }
  }
}
