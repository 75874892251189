  .close-icon {
    cursor: pointer;
  }
  .modal-buttons{
    justify-content: space-between;
    flex-direction: column;
    button {
      margin-right: 0px;
    }
    .second-button{
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 400px) {    
  .modal-buttons{
    flex-direction: row;
    justify-content: space-evenly;
    button {
      margin-right: 0px;
    }
    .second-button{
      margin-top: 0rem;
    }
  }
  }
