.table-pagination-con{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination {
    .page-item {
      margin-right: 10px;
      .page-link {
        width: 34px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #D8DADB80;
        padding: 0px;
        font-family: "SourceSansOpen600";
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.15714286267757416px;
        color: #444444c4;
        span {
          i {
            line-height: 2;
            color: #444444;
          }
        }
      }
    }
    .page-item.indicators {
      .page-link {
        width: 40px;
        height: 32px;
        color: #ffffff;
      }
    }
    .page-item.active {
      .page-link {
        width: 31px;
        height: 32px;
        color: #ffffff;
      }
    }
    .sorting-con{
      .sorting-label{
        margin-left: 15px;
      }
    }
  }
 @media (max-width: 400px) {
  flex-direction: column;
  align-items: flex-start;
  .sorting-con{
    .sorting-label{
      margin-left: 0px;
    }
  }
 }
}